import { LatLng } from "leaflet";
import { determineNewAreaName } from "../shared/shapeHelper";
export default class Rect {
    constructor(id = "Rec" + ++Rect.counter, center, bounds, processed, lux3ExperimentId, confluenceThreshold, visible) {
        this.type = "rectangle";
        this.display = false;
        this.visible = true;
        this.id = "";
        this.selected = false;
        this.color = "#F8C47B";
        this.lux3ExperimentId = "";
        this.processed = false;
        this.confluenceThreshold = 0;
        this.offBounds = false;
        this.selectedAlgorithm = null;
        this.wellName = undefined;
        this.id = id;
        this.center = center;
        this.bounds = bounds;
        this.processed = processed;
        this.rightDown = new LatLng(bounds[1][0], bounds[1][1]);
        this.rightUp = new LatLng(bounds[0][0], bounds[1][1]);
        this.leftDown = new LatLng(bounds[1][0], bounds[0][1]);
        this.leftUp = new LatLng(bounds[0][0], bounds[0][1]);
        this.midUp = new LatLng(bounds[1][0], (bounds[1][1] + bounds[0][1]) / 2);
        this.midDown = new LatLng(bounds[0][0], (bounds[0][1] + bounds[1][1]) / 2);
        this.midRight = new LatLng((bounds[0][0] + bounds[1][0]) / 2, bounds[0][1]);
        this.midLeft = new LatLng((bounds[1][0] + bounds[0][0]) / 2, bounds[1][1]);
        this.lux3ExperimentId = lux3ExperimentId;
        this.confluenceThreshold = confluenceThreshold;
        this.visible = visible;
    }
    moveShape(diff) {
        if (!this.processed) {
            this.bounds = [
                [this.bounds[0][0] + diff.lat, this.bounds[0][1] + diff.lng],
                [this.bounds[1][0] + diff.lat, this.bounds[1][1] + diff.lng]
            ];
            this.rightDown = new LatLng(this.rightDown.lat + diff.lat, this.rightDown.lng + diff.lng);
            this.rightUp = new LatLng(this.rightUp.lat + diff.lat, this.rightUp.lng + diff.lng);
            this.leftDown = new LatLng(this.leftDown.lat + diff.lat, this.leftDown.lng + diff.lng);
            this.leftUp = new LatLng(this.leftUp.lat + diff.lat, this.leftUp.lng + diff.lng);
            this.midUp = new LatLng(this.midUp.lat + diff.lat, this.midUp.lng + diff.lng);
            this.midDown = new LatLng(this.midDown.lat + diff.lat, this.midDown.lng + diff.lng);
            this.midRight = new LatLng(this.midRight.lat + diff.lat, this.midRight.lng + diff.lng);
            this.midLeft = new LatLng(this.midLeft.lat + diff.lat, this.midLeft.lng + diff.lng);
            this.center = new LatLng(this.center.lat + diff.lat, this.center.lng + diff.lng);
        }
    }
    setProcessed() {
        this.processed = true;
    }
    getProcessed() {
        return this.processed;
    }
    clone(shapes) {
        return new Rect(determineNewAreaName("Rec", shapes.ids), this.center, this.bounds, this.processed, this.lux3ExperimentId, this.confluenceThreshold, this.visible);
    }
    setConfluenceThreshold(threshold) {
        this.confluenceThreshold = threshold;
    }
    getConfluenceThreshold() {
        return this.confluenceThreshold;
    }
    getCenter() {
        return this.center;
    }
    setColor(color) {
        this.color = color;
    }
    delete() {
        Rect.counter--;
    }
    changeSize(bounds, center) {
        this.center = new LatLng(center[0], center[1]);
        this.bounds = bounds;
        this.rightDown = new LatLng(bounds[1][0], bounds[1][1]);
        this.rightUp = new LatLng(bounds[0][0], bounds[1][1]);
        this.leftDown = new LatLng(bounds[1][0], bounds[0][1]);
        this.leftUp = new LatLng(bounds[0][0], bounds[0][1]);
        this.midUp = new LatLng(bounds[1][0], (bounds[1][1] + bounds[0][1]) / 2);
        this.midDown = new LatLng(bounds[0][0], (bounds[0][1] + bounds[1][1]) / 2);
        this.midRight = new LatLng((bounds[0][0] + bounds[1][0]) / 2, bounds[0][1]);
        this.midLeft = new LatLng((bounds[1][0] + bounds[0][0]) / 2, bounds[1][1]);
    }
    setoffBounds(offBounds) {
        this.offBounds = offBounds;
    }
}
Rect.counter = 0;
