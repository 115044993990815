import { computed, reactive, ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { setupProvider } from "@/globals/setupProvider";
import { ExperimentVesselType } from "@/pages/wellPlate/_providers/types/enums/experimentTypes";
import { useBreadCrumbs } from "@/shared/components/navigation/BreadCrumbs/withBreadCrumbs";
import router from "@/router";
import OmniExperimentType from "./types/enums/OmniExperimentType";
const Omni3v1ExperimentsRepository = RepositoryFactory.omni3v1.experiments;
export function withExperiment(experimentId) {
    var _a;
    const { setBreadCrumbs } = useBreadCrumbs();
    const experiment = reactive({
        name: "",
        id: experimentId,
        start: 0,
        owner: {
            Name: "",
            Email: ""
        },
        isReadOnly: false,
        deviceId: 0,
        logBook: [],
        fetched: false,
        projectId: "",
        projectName: "",
        vesselType: 0,
        experimentType: 0,
        pixelsPerMM: 0,
        snapshotInterval: 0,
        scanEstimateInMinutes: 0,
        thumbnailUrl: "",
        sliceStorage: "",
        hasLoaded: false,
        hasAreas: true,
        channels: [],
        selectedAlgorithms: [],
        baseExportFunctionAppUrl: "",
        omniOrganoidSummaryApiEndpoint: "",
        omniColonySummaryApiEndpoint: "",
        omniPSCSummaryApiEndpoint: "",
        wellplateDetails: {
            name: "",
            height: 0,
            width: 0,
            interWellDistanceX: 0,
            interWellDistanceY: 0,
            wellOffsetX: 0,
            wellOffsetY: 0,
            nrOfColumns: 0,
            nrOfRows: 0,
            nrOfWells: 0,
            wellDiameter: 0
        },
        isOmniBr: false
    });
    const isEditMode = ref(false);
    const isApp = ref((((_a = router.currentRoute.query) === null || _a === void 0 ? void 0 : _a.inAppMode) + "").toLowerCase() === "true" ||
        false);
    // This const is added cause we are temporary reverting the exp permissions until we release the project level permissions
    const temporaryHideExperimentPermissions = true;
    const isLoggedInUserExperimentOwner = computed(() => isApp.value
        ? false
        : temporaryHideExperimentPermissions ||
            experiment.owner.Email === window.GLOBAL_CURRENT_USER_EMAIL ||
            window.GLOBAL_CURRENT_USER_EMAIL === "masteradmin@cytosmart.com");
    const isFullStitch = computed(() => experiment.experimentType === OmniExperimentType.FULL_SCAN);
    function setBreadcrumbs() {
        setBreadCrumbs([
            {
                name: experiment.projectName,
                url: `/Project/${experiment.projectId}/Experiments`
            },
            {
                name: experiment.name,
                url: null
            }
        ]);
    }
    function setDocumentTitle() {
        document.title = experiment.name + " - Axion";
    }
    function openLuxExperiment(lux3ExperimentId) {
        window.open(`/Project/${experiment.projectId}/Experiments/${lux3ExperimentId}`, "_blank");
    }
    function checkIsExperimentTypeOther() {
        return experiment.vesselType === ExperimentVesselType.OTHER;
    }
    async function fetchExperiment(omni3ExperimentId = experimentId) {
        var _a;
        const { data } = await Omni3v1ExperimentsRepository.getDetails(omni3ExperimentId);
        experiment.name = data.Name;
        experiment.start = data.Start;
        experiment.owner = data.Owner;
        experiment.deviceId = data.DeviceId;
        experiment.projectId = data.ProjectId;
        experiment.projectName = data.ProjectName;
        experiment.vesselType = data.VesselType;
        experiment.experimentType = data.ExperimentType;
        experiment.pixelsPerMM = (_a = data.PixelsPerMm) !== null && _a !== void 0 ? _a : 0;
        experiment.snapshotInterval = data.SnapshotInterval;
        experiment.scanEstimateInMinutes = data.ScanEstimateInMinutes;
        experiment.thumbnailUrl = data.ThumbnailUrl;
        experiment.sliceStorage = data.SliceStorage;
        experiment.selectedAlgorithms = data.AreaAlgorithms;
        experiment.fetched = true;
        experiment.isReadOnly = data.isReadOnly;
        experiment.id = data.Id;
        experiment.wellplateDetails.name = data.WellplateDetails.Name;
        experiment.wellplateDetails.height = data.WellplateDetails.Height;
        experiment.wellplateDetails.width = data.WellplateDetails.Width;
        experiment.wellplateDetails.interWellDistanceX =
            data.WellplateDetails.InterWellDistanceX;
        experiment.wellplateDetails.interWellDistanceY =
            data.WellplateDetails.InterWellDistanceY;
        experiment.wellplateDetails.wellOffsetX = data.WellplateDetails.WellOffsetX;
        experiment.wellplateDetails.wellOffsetY = data.WellplateDetails.WellOffsetY;
        experiment.wellplateDetails.nrOfColumns = data.WellplateDetails.NrOfColumns;
        experiment.wellplateDetails.nrOfRows = data.WellplateDetails.NrOfRows;
        experiment.wellplateDetails.nrOfWells = data.WellplateDetails.NrOfWells;
        experiment.wellplateDetails.wellDiameter =
            data.WellplateDetails.WellDiameter;
        experiment.baseExportFunctionAppUrl = data.BaseExportFunctionAppUrl;
        experiment.omniOrganoidSummaryApiEndpoint =
            data.OmniOrganoidSummaryApiEndpoint;
        experiment.omniColonySummaryApiEndpoint = data.OmniColonySummaryApiEndpoint;
        experiment.isOmniBr = data.IsOmniBr;
        return {
            selectedAlgorithms: data.AreaAlgorithms
        };
    }
    return {
        experiment,
        isLoggedInUserExperimentOwner,
        isApp,
        isFullStitch,
        isEditMode,
        fetchExperiment,
        openLuxExperiment,
        checkIsExperimentTypeOther,
        setBreadcrumbs,
        setDocumentTitle
    };
}
export const { provide: provideExperiment, use: useExperiment } = setupProvider();
