import { LatLng, LatLngBounds } from "leaflet";
import { _getAllWellsFromData, _wellContainsPoint } from "@/pages/wellPlate/_providers/VirtualWellPlateShapeProvider/WellPlateShapesOperations/WellOperations";
import ShapeEnum from "../_providers/types/enums/ShapeEnum";
import CommandCircleCreate from "./commands/CreateCircle";
import CommandPolygonCreate from "./commands/CreatePoly";
import CommandRectCreate from "./commands/CreateRect";
import Circle from "./shapes/circle";
import Polygon from "./shapes/polygon";
import Rect from "./shapes/rect";
function calculateMiddles(inArr) {
    const middles = [];
    for (let i = 0; i < inArr.length - 1; i++) {
        middles.push([
            (inArr[i][0] + inArr[i + 1][0]) / 2,
            (inArr[i][1] + inArr[i + 1][1]) / 2
        ]);
    }
    middles.push([
        (inArr[0][0] + inArr[inArr.length - 1][0]) / 2,
        (inArr[0][1] + inArr[inArr.length - 1][1]) / 2
    ]);
    return middles;
}
// Generates the ROIs properties given the PROIs fetched from backend
export function generateROIs(prois, proisData) {
    return Object.values(prois)
        .sort((a, b) => a.name.localeCompare(b.name, "en", { numeric: true }))
        .map(p => {
        const halfHeight = p.absolutePosition.height / 2;
        const pointY = p.absolutePosition.y;
        const pointX = p.absolutePosition.x;
        return {
            name: p.name,
            center: {
                lat: p.absolutePosition.y,
                lng: p.absolutePosition.x
            },
            bounds: [
                [pointY + halfHeight, pointX + halfHeight],
                [pointY - halfHeight, pointX - halfHeight]
            ],
            isSelected: false,
            color: proisData[p.name].color,
            lux3ExperimentId: proisData[p.name].lux3ExperimentId,
            point: p.absolutePosition,
            style: {
                weight: 2
            }
        };
    });
}
/** Used to generate the well names based on the number of wells */
function getNameForWell(pointX, pointY, amountOfPointsY) {
    const asciiCapitalA = 65;
    const wellLetter = String.fromCharCode(asciiCapitalA + pointX);
    const wellNumber = pointY + (1 % amountOfPointsY);
    return `${wellLetter}${wellNumber}`;
}
/** Generates the inner well plate background */
export const generateInnerWellPlateBackground = function (wellPlateData) {
    const padding = 1.5;
    const result = [];
    const startPostition = [
        wellPlateData.wellOffsetY - wellPlateData.wellDiameter / 2 - padding,
        wellPlateData.wellOffsetX - wellPlateData.wellDiameter / 2 - padding
    ];
    result.push(startPostition);
    result.push([
        wellPlateData.wellOffsetY +
            (wellPlateData.nrOfRows - 1) * wellPlateData.interWellDistanceY +
            wellPlateData.wellDiameter / 2 +
            padding,
        wellPlateData.wellOffsetX - wellPlateData.wellDiameter / 2 - padding
    ]);
    result.push([
        wellPlateData.wellOffsetY +
            (wellPlateData.nrOfRows - 1) * wellPlateData.interWellDistanceY +
            wellPlateData.wellDiameter / 2 +
            padding,
        wellPlateData.wellOffsetX +
            (wellPlateData.nrOfColumns - 1) * wellPlateData.interWellDistanceX +
            wellPlateData.wellDiameter / 2 +
            padding
    ]);
    result.push([
        wellPlateData.wellOffsetY - wellPlateData.wellDiameter / 2 - padding,
        wellPlateData.wellOffsetX +
            (wellPlateData.nrOfColumns - 1) * wellPlateData.interWellDistanceX +
            wellPlateData.wellDiameter / 2 +
            padding
    ]);
    result.push(startPostition);
    return result;
};
/**  Generates the background well shapes based on the wellPlate type
 * 65 - ASCII code for A
 * 3.5 - distance of letter from the well
 */
export function generateWells(wellPlateData) {
    const result = [], letters = [], numbers = [];
    for (let pointX = 0; pointX < wellPlateData.nrOfColumns; pointX++) {
        for (let pointY = 0; pointY < wellPlateData.nrOfRows; pointY++) {
            const x = wellPlateData.wellOffsetX + pointX * wellPlateData.interWellDistanceX;
            const y = wellPlateData.wellOffsetY + pointY * wellPlateData.interWellDistanceY;
            const r = wellPlateData.wellDiameter / 2;
            result.push({
                x: x,
                y: y,
                r: r,
                w: wellPlateData.wellDiameter,
                h: wellPlateData.wellDiameter,
                strokeWidth: 0.1,
                id: getNameForWell(pointY, pointX, wellPlateData.nrOfWells),
                isSelected: false
            });
            if (pointY === 0) {
                numbers.push({
                    value: pointX + 1,
                    latlng: new LatLng(y - r - 3.5, x)
                });
            }
            if (pointX === 0) {
                letters.push({
                    char: String.fromCharCode(65 + pointY),
                    latlng: new LatLng(y, x - r - 3.5)
                });
            }
        }
    }
    return {
        wells: result,
        letters,
        numbers
    };
}
export function loadShapesVirtualWellPlate(shapes, virtualWellPlateProvider, proisData) {
    Object.values(shapes).forEach(shape => {
        var _a;
        let shapeToAdd;
        const shapes = Object.values(shape.shapes)[0];
        const lux3ExperimentId = (_a = Object.values(proisData).find(proi => proi.name === shape.name)) === null || _a === void 0 ? void 0 : _a.lux3ExperimentId;
        switch (shapes.type) {
            case ShapeEnum.CIRCLE:
                shapeToAdd = new Circle(shape.name, shapes.radius, new LatLng(shapes.center.x, -shapes.center.y), new LatLng(shapes.center.x, -shapes.center.y), true, lux3ExperimentId, 0, true);
                virtualWellPlateProvider.addShape(new CommandCircleCreate(shapeToAdd));
                break;
            case ShapeEnum.RECTANGLE: {
                const bounds = [
                    [-shapes.bounds[0].y, shapes.bounds[0].x],
                    [-shapes.bounds[1].y, shapes.bounds[1].x]
                ];
                shapeToAdd = new Rect(shape.name, new LatLng((-shapes.bounds[1].y + -shapes.bounds[0].y) / 2, (shapes.bounds[1].x + shapes.bounds[0].x) / 2), bounds, true, lux3ExperimentId, 0, true);
                shapeToAdd.type = ShapeEnum.RECTANGLE;
                virtualWellPlateProvider.addShape(new CommandRectCreate(shapeToAdd));
                break;
            }
            case ShapeEnum.POLYGON: {
                const points = [];
                shapes.points.forEach(point => {
                    points.push([-point.y, point.x]);
                });
                const lats = [];
                const lngs = [];
                for (let i = 0; i < points.length; i++) {
                    lats.push(points[i][0]);
                    lngs.push(points[i][1]);
                }
                shapeToAdd = new Polygon(shape.name, points, new LatLng((Math.max(...lats) + Math.min(...lats)) / 2, (Math.max(...lngs) + Math.min(...lngs)) / 2), calculateMiddles(points), true, lux3ExperimentId, 0, true);
                shapeToAdd.type = ShapeEnum.POLYGON;
                virtualWellPlateProvider.addShape(new CommandPolygonCreate(shapeToAdd));
                break;
            }
        }
    });
}
export function loadROIs(rois, virtualWellPlateProvider) {
    rois.forEach(roi => {
        const rect = new Rect(roi.name, new LatLng(roi.center.lat, roi.center.lng), roi.bounds, true, roi.lux3ExperimentId, 0, true);
        rect.type = ShapeEnum.ROI;
        virtualWellPlateProvider.addShape(new CommandRectCreate(rect));
        const well = _getAllWellsFromData(virtualWellPlateProvider.data).find(well => _wellContainsPoint(well, roi.center));
        if (well) {
            rect.wellName = well.id;
        }
    });
}
export function loadWells(prois, wells, virtualWellPlateProvider) {
    wells.forEach(well => {
        const circle = new Circle(well.id, well.r, new LatLng(well.x, well.y), new LatLng(well.x, well.y), true, "", 0, true);
        const cornerNE = new LatLng(circle.center.lat + well.r, circle.center.lng + well.r);
        const cornerSW = new LatLng(circle.center.lat - well.r, circle.center.lng - well.r);
        const anyWellContainsProi = !Object.values(prois).find(proi => new LatLngBounds(cornerNE, cornerSW).contains(new LatLng(proi.absolutePosition.x, proi.absolutePosition.y)));
        if (anyWellContainsProi) {
            circle.visible = false;
        }
        circle.type = ShapeEnum.WELL;
        virtualWellPlateProvider.addShape(new CommandCircleCreate(circle));
    });
}
export function loadShapes(timepoint, areas, areasData, manager) {
    areas.forEach(area => {
        var _a, _b, _c;
        if (area.shapes[timepoint]) {
            const lux3ExperimentId = (_a = Object.values(areasData).find(areaData => areaData.name === area.name)) === null || _a === void 0 ? void 0 : _a.lux3ExperimentId;
            if (area.shapes[timepoint].type === ShapeEnum.CIRCLE) {
                manager.addCommand(new CommandCircleCreate(new Circle(area.name, area.shapes[timepoint].radius, new LatLng(-area.shapes[timepoint].center.y, area.shapes[timepoint].center.x), new LatLng(-area.shapes[timepoint].center.y, area.shapes[timepoint].center.x), true, lux3ExperimentId, 0, (_c = (_b = manager.shapes.byId[area.name]) === null || _b === void 0 ? void 0 : _b.visible) !== null && _c !== void 0 ? _c : true)));
            }
            else if (area.shapes[timepoint].type === ShapeEnum.RECTANGLE) {
                const bounds = [
                    [
                        -area.shapes[timepoint].bounds[0].y,
                        area.shapes[timepoint].bounds[0].x
                    ],
                    [
                        -area.shapes[timepoint].bounds[1].y,
                        area.shapes[timepoint].bounds[1].x
                    ]
                ];
                manager.addCommand(new CommandRectCreate(new Rect(area.name, new LatLng((-area.shapes[timepoint].bounds[1].y +
                    -area.shapes[timepoint].bounds[0].y) /
                    2, (area.shapes[timepoint].bounds[1].x +
                    area.shapes[timepoint].bounds[0].x) /
                    2), bounds, true, lux3ExperimentId, 0, manager.shapes.byId[area.name]
                    ? manager.shapes.byId[area.name].visible
                    : true)));
            }
            else {
                const points = [];
                area.shapes[timepoint].points.forEach(point => {
                    points.push([-point.y, point.x]);
                });
                const lats = [];
                const lngs = [];
                for (let i = 0; i < points.length; i++) {
                    lats.push(points[i][0]);
                    lngs.push(points[i][1]);
                }
                manager.addCommand(new CommandPolygonCreate(new Polygon(area.name, points, new LatLng((Math.max(...lats) + Math.min(...lats)) / 2, (Math.max(...lngs) + Math.min(...lngs)) / 2), calculateMiddles(points), true, lux3ExperimentId, 0, manager.shapes.byId[area.name]
                    ? manager.shapes.byId[area.name].visible
                    : true)));
            }
        }
    });
    manager.head = -1;
    manager.history = [];
}
function getCircle(area) {
    const circle = {
        center: {
            x: area.center.lng,
            y: -area.center.lat
        },
        radius: area.radius,
        shapeType: area.type
    };
    return circle;
}
function getRectangle(area) {
    const rectangle = {
        bounds: [
            {
                x: area.bounds[0][1],
                y: -area.bounds[0][0]
            },
            {
                x: area.bounds[1][1],
                y: -area.bounds[1][0]
            }
        ],
        shapeType: area.type
    };
    return rectangle;
}
function getPolygon(area) {
    const polygon = {
        points: area.points.map(point => {
            return {
                x: point[1],
                y: -point[0]
            };
        }),
        shapeType: area.type
    };
    return polygon;
}
function getDefinitionsBasedOnAreaType(area, unixTimestamp, areaId, circleDefinitions, rectangleDefinitions, polygonDefinitions) {
    switch (area.type) {
        case ShapeEnum.CIRCLE: {
            const circle = getCircle(area);
            circleDefinitions.push({
                AreaDefinition: {
                    name: areaId,
                    unixTimestamp: unixTimestamp,
                    shape: circle
                },
                SelectedAlgorithmType: area.selectedAlgorithm
            });
            break;
        }
        case ShapeEnum.RECTANGLE: {
            const rectangle = getRectangle(area);
            rectangleDefinitions.push({
                AreaDefinition: {
                    name: areaId,
                    unixTimestamp: unixTimestamp,
                    shape: rectangle
                },
                SelectedAlgorithmType: area.selectedAlgorithm
            });
            break;
        }
        case ShapeEnum.POLYGON: {
            const polygon = getPolygon(area);
            polygonDefinitions.push({
                AreaDefinition: {
                    name: areaId,
                    unixTimestamp: unixTimestamp,
                    shape: polygon
                },
                SelectedAlgorithmType: area.selectedAlgorithm
            });
            break;
        }
    }
}
// Gets new areas for processing
export function getNewAreasToProcess(unixTimestamp, manager) {
    const circleDefinitions = [];
    const rectangleDefinitions = [];
    const polygonDefinitions = [];
    const shapesById = Object.keys(manager.shapes.byId);
    shapesById.forEach(areaId => {
        if (manager.shapes.byId[areaId].processed) {
            return;
        }
        const area = manager.shapes.byId[areaId];
        getDefinitionsBasedOnAreaType(area, unixTimestamp, areaId, circleDefinitions, rectangleDefinitions, polygonDefinitions);
    });
    if (!circleDefinitions.length &&
        !rectangleDefinitions.length &&
        !polygonDefinitions.length) {
        return null;
    }
    return { circleDefinitions, polygonDefinitions, rectangleDefinitions };
}
//Get existing areas for processing
export function getExistingAreasToProcess(manager, areas) {
    const existingAreasToProcess = [];
    const shapesById = Object.keys(manager.shapes.byId);
    shapesById.forEach(areaId => {
        const shape = manager.shapes.byId[areaId];
        if (shape.processed && shape.selected && shape.selectedAlgorithm !== null) {
            existingAreasToProcess.push({
                experimentLux3Id: areas[areaId].lux3ExperimentId,
                algorithmId: shape.selectedAlgorithm
            });
        }
    });
    return existingAreasToProcess;
}
