import { LatLng } from "leaflet";
import { determineNewAreaName } from "../shared/shapeHelper";
export default class Polygon {
    constructor(id = "Pol" + ++Polygon.counter, points, center, middles, processed, lux3ExperimentId, confluenceThreshold, visible) {
        this.type = "polygon";
        this.display = false;
        this.visible = true;
        this.id = "";
        this.selected = false;
        this.color = "#F8C47B";
        this.lux3ExperimentId = "";
        this.processed = false;
        this.confluenceThreshold = 0;
        this.offBounds = false;
        this.selectedAlgorithm = null;
        this.id = id;
        this.points = points;
        this.center = center;
        this.middles = middles;
        this.processed = processed;
        this.lux3ExperimentId = lux3ExperimentId;
        this.confluenceThreshold = confluenceThreshold;
        this.visible = visible;
    }
    moveShape(diff) {
        if (!this.processed) {
            this.center = new LatLng(this.center.lat + diff.lat, this.center.lng + diff.lng);
            const arr = [];
            for (let i = 0; i < this.points.length; i++) {
                arr.push([this.points[i][0] + diff.lat, this.points[i][1] + diff.lng]);
            }
            this.points = arr;
            const mid = [];
            for (let i = 0; i < this.middles.length; i++) {
                mid.push([
                    this.middles[i][0] + diff.lat,
                    this.middles[i][1] + diff.lng
                ]);
            }
            this.middles = mid;
        }
    }
    delete() {
        Polygon.counter--;
    }
    updatePoints(points) {
        this.points = points;
        const middles = [];
        const lats = [];
        const lngs = [];
        for (let i = 0; i < points.length - 1; i++) {
            middles.push([
                (points[i][0] + points[i + 1][0]) / 2,
                (points[i][1] + points[i + 1][1]) / 2
            ]);
            lats.push(points[i][0]);
            lngs.push(points[i][1]);
        }
        lats.push(points[points.length - 1][0]);
        lngs.push(points[points.length - 1][1]);
        this.center = new LatLng((Math.min(...lats) + Math.max(...lats)) / 2, (Math.min(...lngs) + Math.max(...lngs)) / 2);
        middles.push([
            (points[0][0] + points[points.length - 1][0]) / 2,
            (points[0][1] + points[points.length - 1][1]) / 2
        ]);
        this.middles = middles;
    }
    middleMove(lat, lng, type) {
        const vertixes = [];
        for (let i = 0; i < this.middles.length; i++) {
            if (i == Number(type)) {
                vertixes.push([lat, lng]);
            }
            else {
                vertixes.push(this.middles[i]);
            }
        }
        this.middles = vertixes;
    }
    getCenter() {
        return this.center;
    }
    setProcessed() {
        this.processed = true;
    }
    getProcessed() {
        return this.processed;
    }
    setColor(color) {
        this.color = color;
    }
    setConfluenceThreshold(threshold) {
        this.confluenceThreshold = threshold;
    }
    getConfluenceThreshold() {
        return this.confluenceThreshold;
    }
    clone(shapes) {
        return new Polygon(determineNewAreaName("Poly", shapes.ids), this.points, this.center, this.middles, this.processed, this.lux3ExperimentId, this.confluenceThreshold, this.visible);
    }
    setoffBounds(offBounds) {
        this.offBounds = offBounds;
    }
}
Polygon.counter = 0;
