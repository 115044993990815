import { computed, defineComponent, onUnmounted, ref, watch } from "vue";
import { LRectangle } from "vue2-leaflet";
import { fitBounds } from "../../_providers/helpers/findAreasIdsWithinBounds";
import { useVirtualWellplate } from "../../_providers/VirtualWellPlateShapeProvider/withVirtualWellplate";
import { useSelect } from "../../_providers/withSelect/withSelect";
import { useGroups } from "../../_providers/withGroups/withGroups";
export default defineComponent({
    components: {
        LRectangle
    },
    props: {
        manager: {
            type: Object,
            default: undefined
        },
        enabledGroupedAreasInteraction: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    setup(props, { emit }) {
        const virtualWellPlateProvider = useVirtualWellplate();
        const bounds = ref([]);
        const down = ref(false);
        const isVirtualWellPlate = computed(() => props.manager === undefined);
        const { getGroupByAreasIds } = useGroups();
        const selector = useSelect();
        const getShapes = () => {
            const shapes = Object.values(virtualWellPlateProvider.data.shapes.byId);
            if (props.enabledGroupedAreasInteraction) {
                return shapes;
            }
            else {
                // filter only shapes that are not in some group
                return shapes.filter(shape => !getGroupByAreasIds([shape.id]));
            }
        };
        const mouseMove = () => {
            if (down.value) {
                if (isVirtualWellPlate.value) {
                    bounds.value = [
                        [
                            virtualWellPlateProvider.data.mouseStart.lat,
                            virtualWellPlateProvider.data.mouseStart.lng
                        ],
                        [
                            virtualWellPlateProvider.data.mouseLocation.lat,
                            virtualWellPlateProvider.data.mouseLocation.lng
                        ]
                    ];
                }
                else {
                    bounds.value = [
                        [props.manager.mouseStart.lat, props.manager.mouseStart.lng],
                        [props.manager.mouseLocation.lat, props.manager.mouseLocation.lng]
                    ];
                }
            }
        };
        const mouseUp = () => {
            if (bounds.value.length != 0) {
                if (isVirtualWellPlate.value) {
                    try {
                        const shapes = getShapes();
                        const selectedAreasIds = fitBounds(bounds.value, shapes);
                        selector.selectItems(selectedAreasIds);
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                else {
                    props.manager.fitBounds(bounds.value);
                }
            }
            emit("deselectGroup");
            down.value = false;
            bounds.value = [];
        };
        const mouseDown = () => {
            if (down.value) {
                mouseUp();
                return;
            }
            down.value = true;
        };
        const mouseStart = computed(() => {
            if (isVirtualWellPlate.value) {
                return virtualWellPlateProvider.getData().mouseStart;
            }
            return props.manager.mouseStart;
        });
        const mouseLocation = computed(() => {
            if (isVirtualWellPlate.value) {
                return virtualWellPlateProvider.getData().mouseLocation;
            }
            return props.manager.mouseLocation;
        });
        watch(mouseStart, (newVal, oldVal) => {
            // This is because changing tab also changes the mouseStart and triggers this watch
            if (newVal === undefined || oldVal === undefined) {
                return;
            }
            mouseDown();
        }, { immediate: true });
        watch(mouseLocation, () => {
            mouseMove();
        }, { immediate: true });
        onUnmounted(() => {
            mouseUp();
        });
        return { virtualWellPlateProvider, bounds, down };
    }
});
